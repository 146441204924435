//权限
import request from '@/utils/request'
export function getSettableMenu(parameter) {
  return request({
    url: '/fds/auth/getSettableMenu',
    method: 'get',
    params: parameter,
  })
}

export function getJobAuthByMenuId(id) {
  return request({
    url: `/fds/auth/getJobAuthByMenuId?menuId=${id}`,
    method: 'get',
  })
}

export function updateMenuAuth(params) {
  return request({
    url: `/fds/auth/updateMenuAuth`,
    method: 'post',
    data: params,
  })
}

//判断租户权限
export function getCurrentEmJobs(id) {
  return request({
    url: '/fds/employee/getCurrentEmJobs',
    method: 'get',
  })
}

//职务列表权限
export function getSettableMenuByJobId(id, type) {
  return request({
    url: `/fds/auth/getSettableMenuByJobId?id=${id}&type=${type}`,
    method: 'get',
  })
}
//职务列表权限保存
export function updateMenuWithJobId(params) {
  return request({
    url: `/fds/auth/updateMenuWithJobId`,
    method: 'post',
    data: params,
  })
}
//员工列表权限保存
export function updateMenuWithEmpId(params) {
  return request({
    url: `/fds/auth/updateMenuWithEmpId`,
    method: 'post',
    data: params,
  })
}
