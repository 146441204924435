// with polyfillsnpm install --ignore-scripts
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import '@/assets/style/index.less'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import 'element-ui/lib/theme-chalk/index.css'
import { Scrollbar } from 'element-ui'

import themePluginConfig from '../config/themePluginConfig'
import axios from 'axios'
Vue.prototype.$axios = axios
// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'
import './mock/index'
import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import Print from 'vue-print-nb'
import { hasPermission } from './utils/hasPermission'
import plugins from './plugins' // plugins
import { filterOption } from '@/utils/index.js'
import debounce from 'lodash.debounce'
Vue.prototype.hasPerm = hasPermission
Vue.prototype.$debounce = debounce
// Global instruction
Vue.use(Print)
Vue.use(plugins)

Vue.prototype.filterOption = filterOption

Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
Vue.use(Scrollbar)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
  router,
  store,
  i18n,
  print,
  // init localstorage, vuex, Logo message
  created: bootstrap,
  render: (h) => h(App),
}).$mount('#app')
