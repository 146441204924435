import request from '@/utils/request'
//分页
export function page (params) {
  return request({
    url: `/fds/attachment/page`,
    method: 'GET',
    params: params,
  })
}
//新增
export function add (params) {
  return request({
    url: `/fds/attachment`,
    method: 'POST',
    data: params,
  })
}
//修改
export function change (params) {
  return request({
    url: `/fds/attachment`,
    method: 'PUT',
    data: params,
  })
}
//树形所有
export function treeAll (params) {
  return request({
    url: `/fds/attachment/treeAll`,
    method: 'GET',
    params: params,
  })
}
//查询单个
export function detail (params) {
  return request({
    url: `/fds/attachment/${id}`,
    method: 'GET',
    params: params,
  })
}
//删除的
export function deleteVideo (id) {
  return request({
    url: `/fds/attachment/${id}`,
    method: 'DELETE',

  })
}
//删除的
export function getToken () {
  return request({
    url: `/fds/oss/getToken`,
    method: 'GET',
  })
}
