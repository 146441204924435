<template>
  <div
    :class="wrpCls"
    class="headerRight"
    style="display: flex; justify-content: center; padding-left: 0; color: #ffffff"
  >
    <div class="left">
      {{ name }}
      <img v-if="isTrue && userInfo.mark" @click="sysOpen" src="@/assets/success.png" alt="" />
      <img @click="sysOpen" v-if="!isTrue && userInfo.mark" src="@/assets/error.png" alt="" />
    </div>

    <span v-if="!this.isMobile" style="font-size: 16px; margin-right: 20px; color: rgba(0, 0, 0, 1)"
      >{{ timeArry.timeText }} {{ timeArry.week }}</span
    >
    <a-icon
      title="视频教程"
      type="video-camera"
      style="font-size: 21px; margin: 13px 20px 0 0; color: rgba(0, 0, 0, 1); cursor: pointer"
      @click="showVideoTree"
    />
    <operation :menu="showMenu" :class="prefixCls" />
    <Contact :menu="showMenu" :class="prefixCls" />
    <Search :menu="showMenu" :class="prefixCls" />
    <Notice :menu="showMenu" :class="prefixCls" />
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
    <sys-com ref="sysComRef" :sysInfo="sysInfo"></sys-com>
    <videoPlay ref="videoPlayRef" :visible="modalVisible" @close="modalVisible = false"></videoPlay>
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import Contact from './contact'
import Search from './search'
import Guanwang from './guanwang'
import Notice from './Notice'
import SelectLang from '@/components/SelectLang'
import Operation from './operation.vue'
import videoPlay from './video.vue'

import storage from 'store'
import { initSystemStatus } from '@/api/login.js'
import { mapGetters } from 'vuex'
import { formatDate } from '@/utils/formatDate'
import SysCom from './sysCom.vue'
export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    Contact,
    Guanwang,
    Notice,
    Search,
    SelectLang,
    Operation,
    SysCom,
    videoPlay,
  },

  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action',
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    topMenu: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMenu: true,
      timer: null,
      timeArry: '',
      currentUser: {},
      name: undefined,
      sysInfo: {},
      isTrue: false,
      videoData: [],
      modalVisible: false,
    }
  },
  created() {
    this.name = storage.get('tenantName')
    this.timeArry = formatDate(new Date())
    this.timeStart()
  },
  computed: {
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true,
      }
    },
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getInitSystemStatus()
    // this.handleSearch()
    setTimeout(() => {
      this.currentUser = { ...this.$store.state.user }
    }, 1500)
  },

  methods: {
    // handleSearch() {
    //   treeAll({ pageNo: 1, pageSize: 999 }).then((res) => {
    //     this.videoData = res.data
    //   })
    // },

    showVideoTree() {
      // this.handleSearch()
      this.$refs.videoPlayRef.handleSearch()
      this.modalVisible = true
    },
    sysOpen() {
      this.$refs.sysComRef.visible = true
    },
    getInitSystemStatus() {
      initSystemStatus().then((res) => {
        this.sysInfo = res.data
        this.isTrue = Object.keys(this.sysInfo).every((key) => {
          return this.sysInfo[key] === true
        })
        console.log(this.isTrue, 312312)
      })
    },
    timeStart() {
      // 设置定时器
      this.timer = setInterval(() => {
        this.timeArry = formatDate(new Date())
      }, 1000)
    },
  },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 570px) {
  /* 在此处编写对应的样式 */
  .headerRight {
    background: #39799e;
  }
}
.headerRight {
  .left {
    display: flex;
    align-items: center;
    position: absolute;
    left: 100px;
    color: rgba(40, 150, 237, 1);
    font-size: 16px;
    img {
      margin: 2px 0 0 8px;
      width: 19px;
      height: 19px;
      cursor: pointer;
    }
  }
}
</style>
