<template>
  <a-modal
    title="视频库"
    :visible="visible"
    width="80%"
    @cancel="handleCancel"
    :footer="null"
    class="video-library-modal"
  >
    <div class="search-box">
      <a-input-search
        v-model="searchName"
        placeholder="请输入视频名称搜索"
        style="width: 300px; margin: 16px"
        @search="handleSearch"
      />
    </div>

    <div class="content-wrapper">
      <!-- 左侧树形目录 -->
      <div class="tree-container">
        <a-tree :treeData="filteredTree" :replaceFields="replaceFields" @select="handleTreeSelect">
          <template #title="{ fileType, attachName }">
            <span>
              <a-icon :type="fileType === 'r' ? 'folder' : 'file'" />
              <span v-html="highlightMatch(attachName)"></span>
            </span>
          </template>
        </a-tree>
      </div>

      <!-- 右侧视频预览 -->
      <div class="video-container">
        <div v-if="videoUrl" class="video-wrapper">
          <video
            ref="videoPlayer"
            :src="videoUrl"
            controls
            controlslist="nodownload"
            autoplay
            class="video-element"
          ></video>
        </div>
        <div v-else class="video-placeholder">
          <a-icon type="play-circle" style="font-size: 48px; color: #999" />
          <p>请从左侧选择视频文件</p>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import OSS from 'ali-oss'
import { getToken } from '@/api/video.js'
import { treeAll } from '@/api/video.js'

export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      replaceFields: {
        children: 'children',
        title: 'attachName',
        key: 'id',
      },
      videoUrl: null,
      ossmessage: null,
      searchName: '',
      rawTreeData: [],
    }
  },

  computed: {
    filteredTree() {
      if (!this.searchName) return this.processTree(this.rawTreeData)
      return this.filterTree(this.rawTreeData, this.searchName.toLowerCase())
    },
  },

  mounted() {
    this.getTokenData()
    // this.handleSearch()
  },

  methods: {
    handleSearch() {
      treeAll({ pageNo: 1, pageSize: 999 }).then((res) => {
        this.rawTreeData = res.data
      })
    },

    processTree(nodes) {
      return nodes.map((node) => ({
        ...node,
        children: node.children?.length ? this.processTree(node.children) : undefined,
      }))
    },

    filterTree(nodes, searchText) {
      return nodes
        .filter((node) => {
          const isMatch = node.attachName.toLowerCase().includes(searchText)

          if (node.children && node.children.length) {
            const filteredChildren = this.filterTree(node.children, searchText)
            if (filteredChildren.length) {
              node.children = filteredChildren
              return true
            }
          }

          return isMatch
        })
        .map((node) => ({
          ...node,
          children: node.children ? this.filterTree(node.children, searchText) : [],
        }))
    },

    highlightMatch(text) {
      if (!this.searchName) return text
      const regex = new RegExp(`(${this.searchName})`, 'gi')
      return text.replace(regex, '<span style="color: #f50">$1</span>')
    },

    async getTokenData() {
      try {
        const res = await getToken()
        this.ossmessage = res.data
      } catch (e) {
        this.$message.error('获取OSS凭证失败')
        console.error('获取Token失败:', e)
      }
    },

    initOSSClient() {
      return new OSS({
        region: 'oss-cn-fuzhou',
        accessKeyId: this.ossmessage?.accessKeyId,
        accessKeySecret: this.ossmessage?.accessKeySecret,
        stsToken: this.ossmessage?.securityToken,
        bucket: 'jxctest',
        secure: true,
      })
    },

    generateVideoUrl(fileKey) {
      const ossClient = this.initOSSClient()
      return ossClient.signatureUrl(fileKey, {
        expires: 3600 * 24,
        response: {
          'content-disposition': 'inline',
        },
      })
    },

    handleTreeSelect(selectedKeys, { node }) {
      if (node.dataRef.fileType === 'f') {
        if (this.$refs.videoPlayer) {
          this.$refs.videoPlayer.pause()
          this.$refs.videoPlayer.currentTime = 0
        }
        this.videoUrl = this.generateVideoUrl(node.dataRef.fileKey)
      }
    },

    handleCancel() {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause()
        this.$refs.videoPlayer.currentTime = 0
        this.$refs.videoPlayer.removeAttribute('src')
      }
      this.videoUrl = null
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.video-library-modal .ant-modal-body {
  padding: 0;
}

.search-box {
  border-bottom: 1px solid #e8e8e8;
}

.content-wrapper {
  display: flex;
  height: 60vh;
}

.tree-container {
  width: 35%;
  padding: 16px;
  border-right: 1px solid #e8e8e8;
  overflow: auto;
}

.video-container {
  flex: 1;
  padding: 24px;
  background: #f8f8f8;
}

.video-wrapper {
  height: 100%;
  background: #000;
  border-radius: 4px;
  overflow: hidden;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-placeholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
}

.ant-tree li span.ant-tree-switcher {
  display: none !important;
}

.ant-tree li .ant-tree-node-content-wrapper {
  padding-left: 8px;
  transition: none;
}
</style>
