export const authList = [
  'InStoreIndex',
  'InStoreRecordIndex',
  'BackStoreRecordIndex',
  'ReturnFactoryShopRecordIndex',
  'StoreShopIndex',
  'WholesaleSellIndex',
  'WholesaleSellRecordIndex',
  'Backindex',
  'BackRecordIndex',
  'CashierSellIndex',
  'SellRecordShopIndex',
  'CreateFinanceIncomeIndex',
  'CashierPaymentRecordIndex',
  'CounterShiftIndex',
  'CounterShiftRecordIndex',
  'ShopShiftIndex',
  'ShopShiftRecordIndex',
  'ServiceCreateServiceIndex',
  'ServiceServiceIndex',
  'ServiceServiceRecordIndex',
  'InventoryStart',
  'InventoryRecordIndex',
  'DayReportIndex',
  'ShopReportIndex',
  'InStoreAutoIndex',
  'SellPageDetails',
]
export const authDetailList = [
  { name: 'SellPageDetails', path: '/cashier/sellPage' },
  { name: 'InStoreIndex', path: '/purchasing/inStore' },
  { name: 'InStoreRecordIndex', path: '/purchasing/inStoreRecord' },
  { name: 'BackStoreRecordIndex', path: '/purchasing/backstorerecord' },
  { name: 'ReturnFactoryShopRecordIndex', path: '/purchasing/returnFactoryShopRecord' },
  { name: 'StoreShopIndex', path: '/stock/storeShop' },
  { name: 'WholesaleSellIndex', path: '/wholesale/sell/sellshop' },
  { name: 'WholesaleSellRecordIndex', path: '/wholesale/sellrecord' },
  { name: 'Backindex', path: '/wholesale/back' },
  { name: 'BackRecordIndex', path: '/wholesale/backRecord' },
  { name: 'CashierSellIndex', path: '/cashier/sell' },
  { name: 'SellRecordShopIndex', path: '/cashier/list' },
  { name: 'CreateFinanceIncomeIndex', path: '/cashier/createFinanceIncomeShop/create' },
  { name: 'CashierPaymentRecordIndex', path: '/cashier/paymentRecord' },
  { name: 'CounterShiftIndex', path: '/shift/counterShift' },
  { name: 'CounterShiftRecordIndex', path: '/shift/counterShiftRecord' },
  { name: 'ShopShiftIndex', path: '/shift/shopShift/counterId' },
  { name: 'ShopShiftRecordIndex', path: '/shift/shopShiftRecord' },
  { name: 'ServiceCreateServiceIndex', path: '/service/createservice' },
  { name: 'ServiceServiceIndex', path: '/service/service' },
  { name: 'ServiceServiceRecordIndex', path: '/service/servicerecord' },
  { name: 'InventoryStart', path: '/inventory/start/shop' },
  { name: 'InventoryRecordIndex', path: '/inventory/record' },
  { name: 'DayReportIndex', path: '/report/dayReport' },
  { name: 'ShopReportIndex', path: '/report/shopReport' },
  { name: 'InStoreAutoIndex', path: '/purchasing/inStoreAuto' },
]
