<template>
  <a-dropdown placement="bottomRight">
    <div class="header-icon">
      <img src="@/assets/notice.png" alt="" />
      <a-badge :count="total" class="numNotice">
        <a href="#" class="head-example" />
      </a-badge>
    </div>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item style="text-align: center" v-for="i in noticeList.list" :key="i.id" @click="handleToCenter1(i.id)">
          {{ i.msgTitle }}
        </a-menu-item>
        <a-menu-item @click="handleToCenter" style="text-align: center">
          查看全部 <a-icon type="arrow-right" />
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
import { myMsgListUsingPOST } from '@/api/api/wode.ts'
export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      noticeList: [],
      total: 0,
    }
  },
  mounted() {
    this.getNotiveList()
  },
  methods: {
    getNotiveList() {
      myMsgListUsingPOST({ pageNo: 1, pageSize: 3, queryCondition: '', status: 0 }).then((res) => {
        this.noticeList = res.data
        this.total = res.data.total
      })
    },
    handleToCenter() {
      this.$router.push({ path: '/my/message' })
    },
    handleToCenter1(id) {
      this.$router.push({ path: `/my/message/detail/${id}` })
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
  },
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  :deep(.action) {
    margin-right: 8px;
  }
  :deep(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
.header-icon {
  position: relative;
}
.numNotice {
  position: absolute;
  left: 20px;
  top: 7px;
}
</style>
