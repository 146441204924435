<template>
  <a-modal :title="'系统初始化'" :width="1200" :visible="visible" :footer="null" @cancel="visible = false">
    <div class="sys">
      <div class="sysLi" v-for="(li, index) in dataSys" :key="index" @click.prevent="routeEvent(li.url)">
        <a-result :status="sysInfo[li.field] ? 'success' : 'error'" :title="li.title"></a-result>
      </div>
    </div>
  </a-modal>
</template>

<script>
const dataSys = [
  { title: '门店配置', field: 'storeInitStatus', url: '/shop/index' },
  { title: '员工列表', field: 'empInitStatus', url: '/user/user/index' },
  { title: '增加商品', field: 'goodsInitStatus', url: '/goods/name/index' },
  { title: '供应商&经销商', field: 'supplierInitStatus', url: '/agent/index' },
  { title: '收款账户', field: 'accountInitStatus', url: '/cashier/account/index' },
  { title: '支付方式', field: 'paymentMethodInitStatus', url: '/system/paytype' },
  { title: '运营商业务配置', field: 'opBusinessinitStatus', url: '/mobile/mobile/index' },
  { title: '售后业务配置', field: 'svBusinessInitStatus', url: '/service/goods' },
]
export default {
  props: {
    sysInfo: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {},
  data() {
    return {
      dataSys,
      visible: false,
    }
  },
  methods: {
    routeEvent(url) {
      this.$router.push({ path: url })
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.sys {
  display: flex;
  .sysLi {
    cursor: pointer;
  }
}
</style>
